<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="col-5 bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <!-- <bookmarks /> -->
      <v-select
        v-model="business"
        :options="businesses"
        @change="changeBusiness"
        item-text="text"
        item-value="value"
        :clearable="false"
      />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale /> -->
      <dark-Toggler class="d-lg-block" />
      <!-- <search-bar /> -->
      <!-- <cart-dropdown /> -->
      <!-- <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BFormSelect
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    BFormSelect,
    vSelect,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    changeBusiness() {

      localStorage.setItem('business-id', this.business)

      let businessesList = JSON.parse(localStorage.getItem('businesses'))
      let userData = JSON.parse(localStorage.getItem('userData'))
      
      businessesList.forEach(element => {
        if (element.businessId == this.business) {
          userData.role = element.role
          return
        }
      });

      localStorage.setItem('userData', JSON.stringify(userData))

      location.reload()
    }
  },
  data() {
    return {
    }
  },
  setup() {
    let businessesList = JSON.parse(localStorage.getItem('businesses'))
    let business_options = []
    businessesList.forEach(element => {
      business_options.push({
        value: element.profileId,
        label: element.title
      })
    });
    
    const businesses = business_options
    const business = business_options.filter(bus => bus.value == localStorage.getItem('business-id'))[0]

    return {
      businesses,
      business
    }
  }
}
</script>

<style lang="scss">

.navbar .v-select {
  width: 100%;
}

</style>
